import React, { useContext } from 'react'
import { LessonStoreContext } from './LessonStoreContext'

const LessonList = ({ lessons, selectedLessons, selectLesson }) => {

  function classNames (...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
        {lessons.map((lesson) => (
          <li key={lesson.name} className="col-span-1 flex rounded-md shadow-sm">
            <div
              className={classNames(
                lesson.bgColor,
                'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              )}
            >
              <img alt="Image of the lesson" src={lesson.images[0]} />
            </div>
            <div
              className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
              <div className="flex-1 whitespace-normal px-4 py-2 text-sm">
                <a href={lesson.href} className="font-medium text-gray-900 hover:text-gray-600">
                  {lesson.name}
                </a>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Select</span>
                  <input id={`item ${lesson.id}`} type="checkbox"
                         checked={selectedLessons.includes(lesson.id)}
                         onChange={() => selectLesson(lesson.id)}
                         className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )

}

export const SalesTable = ({ openCheckout, selectLesson, selectedLessons }) => {
  const ctx = useContext(LessonStoreContext)
  const lessons = ctx?.lessons ?? []
  return <div className="px-4 sm:px-6 lg:px-8">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Individual classes</h1>
        <p className="mt-2 text-sm text-gray-700">Here's is a list of available individual classes. Select the ones you
          want to purchase and click the checkout button.</p>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button type="button"
                onClick={openCheckout}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">Checkout
        </button>
      </div>
    </div>
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <LessonList lessons={lessons} selectLesson={selectLesson} selectedLessons={selectedLessons} />
      </div>
    </div>
  </div>
}
