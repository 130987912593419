import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { PageTitle } from '../components/Common/PageTitle'
import { JoinNewsLetterButton } from '../components/Common/NewsLetterPlug'
import React from 'react'
import { LessonStore } from '../components/Shop/LessonStore'

const LessonStorePage = () => {
  const bannerImg = 'http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png'
  const attributes = {
    title: 'Lesson Store',
    description: 'Buy Individual Lessons'
  }
  return <Layout
    title="Wax Carvers - Lesson Store" description="Don't want to buy the full calendar? Get individual lessons here!">
    <Helmet>

      <title>{attributes.title} ¦ Wax Carvers</title>
      <meta name="description" content={attributes.description} />
      <meta property="og:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="og:image" content={bannerImg} />
      <meta property="og:url" content={`https://www.waxcarvers.com/lesson-store`} />
      <meta property="og:description" content={attributes.description} />

      <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
      <meta itemProp="description" content={attributes.description} />

      <meta property="twitter:title" content={`${attributes.title} | Wax Carvers`} />
      <meta property="twitter:description" content={attributes.description} />
      <meta property="twitter:card" content={bannerImg} />
      <meta property="twitter:image:src" content={bannerImg} />
    </Helmet>
    <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <PageTitle title="Get Individual Lessons" />
        <div className="mt-12">
          <LessonStore />
        </div>
      </div>
    </div>
    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>
}
export default LessonStorePage
