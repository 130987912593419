import { SalesTable } from './SalesTable'
import React, { useCallback, useState } from 'react'
import { SlideOut } from '../Common/SlideOut'
import LessonStoreContext from './LessonStoreContext'

const LessonStoreContents = () => {
  const [slideOutOpen, setSlideOutOpen] = useState(false)
  const [selectedLessons, setSelectedLessons] = useState([])
  const selectLesson = useCallback((id) => {
    if (selectedLessons.includes(id)) {
      setSelectedLessons(selectedLessons.filter(it => it !== id))
    } else {
      setSelectedLessons([...selectedLessons, id])
    }
  }, [selectedLessons, setSelectedLessons])
  return (<>
    <SlideOut isOpen={slideOutOpen}
              setOpen={setSlideOutOpen}
              selectLesson={selectLesson}
              selectedLessons={selectedLessons} />
    <section className="mt-12 mx-auto container">
      <SalesTable openCheckout={() => setSlideOutOpen(true)}
                  selectLesson={selectLesson}
                  selectedLessons={selectedLessons} />
    </section>
  </>)
}

export const LessonStore = () =>
  <LessonStoreContext>
    <div>
      <LessonStoreContents />
    </div>
  </LessonStoreContext>

